import { Link } from 'react-router-dom';
import '../App.css';
import { useEffect, useState } from "react";

function Home() {
const [title, setTitle] = useState("CPA Battleground");
  useEffect(() => {
    // This will run when the page first loads and whenever the title changes
    document.title = title;
  }, [title]);

  return (
    <>
    <div class='page_header'>
      <body>
        <img src="https://play.cpabattleground.com/cpabshield2.png" alt='logo' width='400' height='400'></img>
        <br/>
      </body>
    </div>
    <div class='play_but'>
      <body>
        <a href="https://play.cpabattleground.com"><button className='play_button'> <b>Play</b></button></a>
      </body>
    </div>
    <div class='home_text'>
      <h3>Once upon a time, there was a game named CPATG.</h3>
      <h3>On that game, over 100 penguin armies fought against each other, in what ended up becoming the craziest battlefield in CPPS Army history!</h3>
      <h3>The CPPS Army history, which began in 2017, has a lot of significant moments. Moments of glory, moments of honor!</h3>
      <h3>CPATG may now be gone, but penguins must keep on fighting. And CPA Battleground will provide them the environment to do that the best way possible!</h3>
      <h3>Click the Play button above and join the fight!</h3>
    </div>
    </>
  );
}

export default Home;