import './App.css';
import Home from './pages/homepage';
import CommandList from './pages/commands';
import Rules from './pages/rules';
import PrivacyPolicy from './pages/privacy';
import PageHeader from './components/header';
import Play from './pages/play';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

function App() {
  return (
    <Router>
      <div className="App">
        <PageHeader />
        <Switch>
          <Route path="/" exact component={Home}/>
          <Route path="/commands" component={CommandList} />
          <Route path="/rules" component={Rules} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route path="/play" component={Play} />
        </Switch>
      </div>
    </Router>
  );

}

export default App;
