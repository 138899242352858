import { Link } from 'react-router-dom';
import '../App.css';

function Nav() {
    return (
        <nav class="header_nav">
            <Link to="/" className="nav_home" />
            <ul itemscope="" itemtype="http://www.schema.org/SiteNavigationElement" class="nav_main">
                <li><a href="https://play.cpabattleground.com"> Play </a></li>
                <li><Link to="/commands"> Commands </Link></li>
                <li><Link to="/rules"> Rules </Link></li>
                <li><Link to="/privacy-policy"> Privacy Policy </Link></li>
                <li itemprop="name"><a itemprop="url" class="agegate_link" href="https://discord.gg/CweNR4ngGk" target="_blank" rel="noreferrer">Discord</a></li>
            </ul>
        </nav>
    );
}

export default Nav;