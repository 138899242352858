import '../App.css';
import Nav from './nav';

function PageHeader() {
    return (
      <header id="header" class="page_header">
          <Nav />
      </header>
    );
}

export default PageHeader;