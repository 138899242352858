import '../App.css'
import { useEffect, useState } from "react";


function CommandList() {
const [title, setTitle] = useState("Commands | CPA Battleground");
  useEffect(() => {
    // This will run when the page first loads and whenever the title changes
    document.title = title;
  }, [title]);

  return (
        <div class='commands_list'>
        <h1 className='header_text'> List of Commands</h1>
        <h2>General Commands:</h2>
        <p>!ai : Add Item (followed by item id). For example !ai 413.</p>
        <p>!af : Add Furniture Item (followed by furniture item id). For example !af 303.</p>
        <p>!ac : Add Coins (followed by coin amount). For example !ac 5000.</p>
        <p>!jr : Join Room (followed by room id). For example !jr 100.</p>
        <p>!users or !u : Find amount of online users.</p>
        <p>!ping : Check connection.</p><br></br>
        <h2>Army Commands:</h2>
        <p>!army or !a : Join Army (followed by army shortname). For example !army ST. To leave an army do !army NONE.</p>
        <p>!size or !s : View amount of currently online troops of an army on your server. For example !size ST.</p>
        <p>!sizeroom or !sr : View amount of currently online troops of an army on your current room. For example !sizeroom ST.</p>
        <p>!lockouts or !lc : View amount of currently online troops of an army on different rooms than your current one. For example !lockouts ST.</p><br></br><br></br>
       </div>
  );
}

export default CommandList;