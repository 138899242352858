import '../App.css'
import { useEffect, useState } from "react";


function Rules() {
const [title, setTitle] = useState("Rules | CPA Battleground");
  useEffect(() => {
    // This will run when the page first loads and whenever the title changes
    document.title = title;
  }, [title]);

  return (
<div class='rules_text'>
<p dir="ltr">Please remember that we cannot write down every rule, please utilize common sense at all times! Rules are subject to change and are ultimately up to the discretion of the CPAB administration and moderation team. The Rules are general guidelines but each case is judged individually and final decision on punishments always falls upon the Moderation team.</p>
<p>&nbsp;</p>
<h1 dir="ltr"><a href="http://cpabattleground.com/">In-game</a>&nbsp;&sect;1:</h1>
<h2 dir="ltr">General Rules</h2>
<p>&nbsp;</p>
<p dir="ltr">* All content must be PG-friendly.</p>
<p>&nbsp;</p>
<p dir="ltr">* Do not encourage or order others to break the rules listed within this rulebook.</p>
<p>&nbsp;</p>
<p dir="ltr">* Do not threaten others with violence, harassment, leaks of information, or any other form of assault.</p>
<p>&nbsp;</p>
<p dir="ltr">* Do not attempt to bypass the chat filter.</p>
<p>&nbsp;</p>
<p dir="ltr">* Do not indirectly or directly encourage or participate in hate/discrimination against: race/ethnicity, sexual origin, sexual orientation, anything related to gender, religion, age, and, physical or mental disabilities.</p>
<p dir="ltr">* Do not create outfits associated with hate groups, nor create formations related to those groups&nbsp;e.g. swastika.</p>
<p>&nbsp;</p>
<p dir="ltr">* Do not discuss or promote unlawful or highly restricted activities, as CPAB is a game for all ages.&nbsp;</p>
<p dir="ltr">*&nbsp;E.g. Illegal/ Non-Illegal Drugs (including pills, syringes, tobacco, e-cigs, etc.), alcohol</p>
<p>&nbsp;</p>
<p dir="ltr">* Do not discuss global tragedy and tragic events in-game</p>
<p dir="ltr">*&nbsp;E.g. Mass shootings, current or past wars, human or civil rights violations, or natural disasters.</p>
<p>&nbsp;</p>
<p dir="ltr">* Do not interrupt events by trolling or attending a battle that you are not permitted to attend.</p>
<p dir="ltr">*&nbsp;E.g. You cannot spectate major tournaments or war battles (that involve major armies or are deemed close in size) if you have been asked to log off by a member of the judging team.</p>
<p>&nbsp;</p>
<p dir="ltr">* Do not purposely instigate or escalate drama purposely.</p>
<p>&nbsp;</p>
<p dir="ltr">* Do not advertise external/unrelated servers, websites, services, or companies.</p>
<p>&nbsp;</p>
<p dir="ltr">* If you are banned, do not use another account in an attempt to ban evade.&nbsp;</p>
<p>&nbsp;</p>
<p dir="ltr">* Do not attempt to hack, DDOS, scam, spread malware, or any other misuse of technology in that regard.</p>
<p dir="ltr"><span class="Apple-tab-span">&nbsp; &nbsp;&nbsp;</span>* Pressuring one to use unreputable websites or share passwords is disallowed.</p>
<p>&nbsp;</p>
<h1 dir="ltr">Punishments &sect;1.1:</h1>
<p>&nbsp;</p>
<h3 dir="ltr">Permanent Ban:</h3>
<p>&nbsp;</p>
<p dir="ltr">* Revealing personal information about a target, without the target&rsquo;s express consent can result in a permanent ban.</p>
<p dir="ltr"><span class="Apple-tab-span">&nbsp; &nbsp;&nbsp;</span>* Punishment can be less severe depending on the context, the resulting punishment is up to the discretion of CPAB moderators involved with the situation.</p>
<p>&nbsp;</p>
<p dir="ltr">* The creation of inappropriate tactics or forms and ordering troops to send/create them can lead to bans between 1 week and a permanent ban.&nbsp;</p>
<p dir="ltr"><span class="Apple-tab-span">&nbsp; &nbsp;&nbsp;</span>* Heaviest punishment to the person who ordered the tactic, heavy on participating leaders if they didn't try to stop it, and potentially a smaller one on participating troops depending on the severity. The punishment for the person who ordered the inappropriate tactic will usually be a permanent ban but that won&rsquo;t necessarily be the case depending on what was said within the tactic.</p>
<p>&nbsp;</p>
<p dir="ltr">* Indirectly or directly encouraging or participating in hate/discrimination activities will be punished differently due to severity.</p>
<p dir="ltr">*&nbsp;E.g. Being racist/ homophobic (e.g. Derogatory slurs: n-word, r-word, f*g, etc.) will result in a permanent ban, however encouraging comments about age will result in a temporary ban.</p>
<p>&nbsp;</p>
<p dir="ltr">* Doxxing or DDosing is an instant ban and notifying any mutual servers depending on the severity, will most likely end in a permanent ban.&nbsp;</p>
<p>&nbsp;</p>
<p dir="ltr">* Multilogging, or creating new accounts in an attempt to ban evade, will lead to the accounts being deactivated. Additionally, multilogging will most likely also cause league-induced repercussions (however, these are out of CPAB moderation control).</p>
<p dir="ltr"><span class="Apple-tab-span">&nbsp; &nbsp;&nbsp;</span>*&nbsp;E.g. Top Ten deductions and potential disqualifications from tournaments.</p>
<p>&nbsp;</p>
<h3 dir="ltr">3+ months:</h3>
<p>&nbsp;</p>
<p dir="ltr">* Violence, harassment, or any other form of assault.</p>
<p dir="ltr">* E.g.&nbsp;Spreading harmful false information about a user will result in a 5-6 month ban.</p>
<p>&nbsp;</p>
<h3 dir="ltr">1 week - 1 month:</h3>
<p>&nbsp;</p>
<p dir="ltr">* Evading a ban will result in the length being doubled for the first instance. Another attempt after being warned results in an automatic week added. Further attempts will  be punished at the discretion of the mod team given the severity.</p>
<p>&nbsp;</p>
<h3 dir="ltr">24 hours - 1 week:</h3>
<p>&nbsp;</p>
<p dir="ltr">* Swearing in-game should receive a 24-hour ban for the first infraction, a second attempt will result in a two-week ban, with the third increasing to one month, etc. This includes abbreviations that include swear words, such as "LMFAO" and "WTF".</p>
<p>&nbsp;</p>
<p dir="ltr">* Troops sending inappropriate tactics will receive a 0 day to 2 week ban depending on severity, and the person who created the tactic, and leaders who send it will receive a longer ban (see permanent ban).</p>
<p>&nbsp;</p>
<p dir="ltr">* Advertising non-related servers in-game is not allowed and will be addressed based on the situation, in most cases it will be a 24-hour ban, however, if repeated the ban will be increased.</p>
<p dir="ltr">Do not discuss global tragedy and tragic events in-game</p>
<p>&nbsp;</p>
<p dir="ltr">* NSFW content is a 24-hour ban for the first infraction and a longer ban for the second, however, if severe the ban could be permanent.&nbsp;</p>
<p>&nbsp;</p>
<p dir="ltr">* Do not discuss or promote unlawful or highly restricted activities, as CPAB is a game for all ages.&nbsp;</p>
<p dir="ltr">*&nbsp;E.g. Illegal/ Non-Illegal Drugs (including pills, syringes, tobacco, e-cigs, etc.), alcohol.</p>
<p>&nbsp;</p>
<h3 dir="ltr">Kick:</h3>
<p>&nbsp;</p>
<p dir="ltr">* Chat filter evasion is prohibited, and can result in a kick, repeated or severe offenses may result in a ban/mute. (The filter is there for a reason)</p>
<p dir="ltr"><span class="Apple-tab-span">&nbsp; &nbsp;&nbsp;</span>* The severity of the message sent and the quantity of past offenses determine the length and type of repercussion faced by the offender.</p>
<p dir="ltr">* If you are spectating a battle and are asked to log off and you do not, you will be kicked from the game. You will also be kicked for interrupting an event by trolling.&nbsp;</p>
<p dir="ltr">* If you keep logging on, the punishment may end in a temporary ban until the event has ended.&nbsp;</p>
<p dir="ltr">* If you are a moderator logging on to the event to moderate, you are exempt from this rule as long as you are respectful and non-disrupting to the event. It is preferable that the moderator is unaffiliated as well.&nbsp;</p>
<p>&nbsp;</p>
<h1 dir="ltr">Usernames &sect;1.2</h1>
<p>&nbsp;</p>
<p dir="ltr">* Please refrain from using:</p>
<p dir="ltr">* Racist/derogatory words/slurs (or anything attributed with such).</p>
<p dir="ltr">* Swear/vulgar/sexual words or terms.</p>
<p dir="ltr">* Names intended to impersonate other people or entities.</p>
<p dir="ltr">* Discriminatory or otherwise offensive words (eg. homophobic slang).</p>
<p dir="ltr">* Names relating to any illicit drugs or activities. (eg. weed and methamphetamine)</p>
<p dir="ltr">* Usernames that encourage rule breaks (eg. IAmMulti)</p>
<p dir="ltr">* Anything that breaks rules within &sect;1.</p>
<p dir="ltr">* Terms with inappropriate connotations such as 69 and 420, the aforementioned numbers can be used if the context is fine. (eg. ZoPenguinIceberg69 is fine).</p>
<p dir="ltr">* Politically-adjacent terms and names. (eg. The names DonaldTrump001oy and Stinks_Vote_Liberal are not fine)</p>
<p dir="ltr">* "There is a limit of 12 characters for a username, please remind troops of this when they are creating accounts!"</p>
<p>&nbsp;</p>
<h1 dir="ltr"><a href="https://discord.gg/8q2rgCGDdE">Discord</a>&nbsp;&sect;2*:</h1>
<p>&nbsp;</p>
<p dir="ltr">*&nbsp;No Swearing:&nbsp;The use of swear words is not allowed. The individual use of swear words will result in messages being deleted, repeated swearing will result in a mute. &nbsp;</p>
<p dir="ltr">* Light swearing&nbsp;on Discord&nbsp;is allowed, as long as it's never rude to others.</p>
<p>&nbsp;</p>
<p dir="ltr">*&nbsp;No Advertising:&nbsp;Advertising external servers is not allowed either in chat or DMs. An individual advert will result in messages being deleted, repeated advertising will result in a ban. &nbsp;</p>
<p>&nbsp;</p>
<p dir="ltr">*&nbsp;No Drama:&nbsp;We do not want drama/personal arguments within the chat. Promoting drama and unfriendly arguments in the server will result in a mute, kick, or ban, depending on the situation. &nbsp;</p>
<p>&nbsp;</p>
<p dir="ltr">*&nbsp;Doxxing or DDosing:&nbsp;Such events are an instant ban and will result in notifying mutual servers depending on the severity.&nbsp;</p>
<p>&nbsp;</p>
<p dir="ltr">*&nbsp;No Inappropriate Topics, Content, or Media:&nbsp;Content, images, and topics must be PG-friendly. Sharing inappropriate content will result in a mute, and then, if repeated, kick or ban. &nbsp;</p>
<p dir="ltr">* Derogatory slurs are instant bans: n-word, r-word, f*g, etc.</p>
<p dir="ltr">* NSFW content is mute for the first infraction and ban for the second.</p>
<p>&nbsp;</p>
<p dir="ltr">*&nbsp;No DM Advertising: DM advertising will be addressed based on the situation.&nbsp;</p>
<p dir="ltr">* A bot advertising an unrelated server will be banned immediately.&nbsp;</p>
<p dir="ltr">* A clueless army member recruiting should be warned and their army leader will be told to address the issue in the army.&nbsp;</p>
<p dir="ltr">* If they don't listen and continue to recruit, the troop will be banned.&nbsp;</p>
<p dir="ltr">* Any advertising in chats will result in a mute.</p>
<p>&nbsp;</p>
<p dir="ltr">*&nbsp;Be nice and use common sense:&nbsp;On a final note, please use common sense and be kind to others.</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p dir="ltr">*Rules are subject to change, see up-to-date rules for the Discord server, see&nbsp;<a href="https://discord.gg/8q2rgCGDdE">here</a>.</p>
<h1 dir="ltr">Closing Statements</h1>
<p dir="ltr">In the end, if you feel something may break the rules, but is not explicitly said here, ask a moderator, or just don&rsquo;t do it! Thank you for helping make CPAB a safe place!</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
</div>
  );
}

export default Rules;